import styled from 'styled-components';
import mediaMin, { breakpoints } from '../../styles/mediaQueries';

export const Modal = styled.div(({ theme }) => (
  {
    position: 'fixed',
    background: '#044275cc',
    width: '100vw',
    height: '100vh',
    padding: '1rem',
    zIndex: 21,

    [mediaMin(breakpoints.md)]: {
      padding: '60px 70px'
    }
  }
));

Modal.Container = styled.div(({ theme }) => (
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
    height: '100%',
    maxWidth: '81.5rem',
    margin: '0 auto',
    padding: '1rem',
    background: theme.white,
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
      width: '7px'
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(0, 0, 0, .06)'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(0, 0, 0, .2)'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.secondary
    }
  }
));

Modal.Button = styled.div(({ theme }) => (
  {
    position: 'relative',
    background: theme.white,
    border: `1px solid ${theme.secondary}`,
    padding: '1.5rem',
    cursor: 'pointer',
    marginBottom: '1rem',
    transition: 'all .2s ease-in-out',

    '&:before, &:after': {
      position: 'absolute',
      left: 'calc((100% - 70%) / 2)',
      top: 'calc((100% - 5px) / 2)',
      width: '70%',
      height: '5px',
      content: `''`,
      backgroundColor: theme.secondary,
      transition: 'all .2s ease-in-out'
    },

    '&:before': {
      transform: 'rotate(45deg)',
    },

    '&:after': {
      transform: 'rotate(-45deg)',
    },

    '&:hover': {
      background: theme.secondary,

      '&:before, &:after': {
        backgroundColor: theme.white,
      }
    },

    '&:active': {
      transform: 'translateY(2px)'
    }
  }
));

Modal.Content = styled.div(({ theme }) => (
  {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '700px',
    height: '100%',
    margin: '0 auto',
  }
));
