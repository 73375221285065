import React from 'react';
import { ProjectsContext } from '../../context/ProjectsManager';
import Img from 'gatsby-image';
import { PreviewAction, WebsiteLink } from '../actions';

import * as S from './styles';
import * as T from '../typography/styles';

const Card = ({ img, children, id, tags, date, fluid, links, action }) => {
  return (
    <ProjectsContext.Consumer>
      {context => (
          <S.Card active={id === context.state} onClick={() => context.action(id)}>
            <S.Card.Header>
              <S.Card.Image>
                <Img fluid={fluid.childImageSharp.fluid}/>
              </S.Card.Image>

              <S.Card.Icon>{links.type === 'website' ? <WebsiteLink href={links.href}/>: <PreviewAction action={action}/>}</S.Card.Icon>
            </S.Card.Header>

            <S.Card.Content>
              <S.Card.Title>
                {children}
                <S.Card.Date>{date}</S.Card.Date>
              </S.Card.Title>

              <S.Card.Tags>
                {tags.map(tag => <T.Tag key={tag}>{tag}</T.Tag>)}
              </S.Card.Tags>
            </S.Card.Content>
          </S.Card>
      )}
    </ProjectsContext.Consumer>
  )
};

export default Card;
