import styled from 'styled-components';
import mediaMin, { mediaMax, breakpoints } from '../../styles/mediaQueries';
import { H1 } from '../typography/styles';
import { Container } from '../layout/styles';

export const Projects = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  [mediaMin(breakpoints.md)]: {
    flexDirection: 'column',
  }
}));

Projects.Title = styled(H1)(({ theme }) => ({
  position: 'relative',
  margin: '0 auto',
  textAlign: 'center',
  marginTop: '3rem',
  color: theme.primary,
}));

Projects.Icon = styled.svg(({ theme }) => ({
  width: '20px',
  height: '20px',
  backgroundColor: theme.white,
}));

Projects.Container = styled.div(
  {
    position: 'relative',
    display: 'flex',
    zIndex: 1,
    flexDirection: 'column',
    width: '100%',
    maxWidth: '90rem',
    margin: '0 auto',

    [mediaMin(breakpoints.md)]: {
      flexDirection: 'row',
      height: '100vh',
    }
  }
);

Projects.Grid = styled.div(
  {
    [mediaMax(breakpoints.md)]: {
      marginTop: '-6rem',
    },

    [mediaMin(breakpoints.md)]: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: '1rem',
      gridAutoRows: 'minmax(100px, auto)',
    },

    [mediaMin(breakpoints.lg)]: {
      gridTemplateColumns: '1fr 1fr',
    },
  }
);

Projects.TagContainer = styled.div(
  {
    display: 'flex',
    flexFlow: 'row wrap',
    padding: '1rem 0 1.5rem',
  }
);

Projects.Button = styled.button(({ theme }) => (
  { 
    position: 'fixed',
    top: '7.5rem',
    padding: '1rem',
    color: theme.white,
    cursor: 'pointer',
    border: `1px solid ${theme.white}`,
    backgroundColor: 'transparent',
    outline: theme.white,
    transition: 'all .1s ease-in-out',

    '&:before, &:after': {
      position: 'absolute',
      left: '33%',
      width: '40%',
      height: '3px',
      content: `''`,
      backgroundColor: theme.white,
      transition: 'all .1s ease-in-out'
    },

    '&:before': {
      transform: 'rotate(40deg)',
      top: '35%'
    },
    
    '&:after': {
      transform: 'rotate(-40deg)',
      bottom: '35%'
    },

    '&:disabled': {
      opacity: .5
    },

    '&:active': {
      background: theme.white,

      '&:before, &:after': {
        backgroundColor: theme.secondary
      },
    },

    [mediaMin(breakpoints.md)]: {
      display: 'none',
    },
  }
));

Projects.ButtonLeft = styled(Projects.Button)(
  { left: '10px', transform: 'rotate(180deg)' }
);

Projects.ButtonRight = styled(Projects.Button)(
  { right: '10px' }
);
