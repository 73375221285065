import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../styles/globalStyles';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import ProjectsManager, { ProjectsContext } from '../context/ProjectsManager';
import theme from '../styles/theme';

import * as S from '../components/projects/styles';
import * as L from '../components/layout/styles';

import Header from '../components/header';
import SEO from '../components/seo';
import Modal from '../components/modal';
import Pattern from '../components/pattern';
import Grid from '../components/grid';
import { CVLink } from '../components/actions';

const Projects = ({ pageContext: { content }, data }) => {
  const projects = content.projects.items;

  return (
    <ThemeProvider theme={theme.projects}>
      <Fragment>
        <SEO />
        <GlobalStyle />

        <L.Page>
          <ProjectsManager>
            <ProjectsContext.Consumer>
              {context => (
                <Fragment>
                  {context.isModalActive && (
                    <Modal action={context.toggleModal}>
                      <Img fluid={data[projects[context.state].links.href].childImageSharp.fluid} style={{ position: 'absolute', top: 0, width: '100%' }}/>
                    </Modal>
                  )}

                  <Header {...content.header } />

                  <S.Projects>
                    <Pattern />
                    <CVLink />
                    <S.Projects.Title>Projects</S.Projects.Title>

                    <S.Projects.Icon width="11px" height="10px" viewBox="0 0 11 10">
                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g transform="translate(-712.000000, -232.000000)" fill="#EE6E65" fillRule="nonzero">
                          <g transform="translate(712.000000, 232.000000)">
                            <polygon transform="translate(5.500000, 5.000000) rotate(45.000000) translate(-5.500000, -5.000000) " points="-0.181721408 5.81723882 -0.11723882 3.81827859 11.1817214 4.18276118 11.1172388 6.18172141"></polygon>
                            <polygon transform="translate(5.500000, 5.000000) rotate(-45.000000) translate(-5.500000, -5.000000) " points="11.1172388 3.81827859 11.1817214 5.81723882 -0.11723882 6.18172141 -0.181721408 4.18276118"></polygon>
                          </g>
                        </g>
                      </g>
                    </S.Projects.Icon>

                    <Grid projects={projects} data={data} action={context.toggleModal}/>
                  </S.Projects>
                </Fragment>
              )}
            </ProjectsContext.Consumer>
          </ProjectsManager>
        </L.Page>
      </Fragment>
    </ThemeProvider>
  )
};

export default Projects;

export const query = graphql`
query {
  dareIt: file(relativePath: { eq: "dareit.png" }) {,
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  tellius: file(relativePath: { eq: "tellius.png" }) {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  webDesign: file(relativePath: { eq: "webDesign.png" }) {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  heating: file(relativePath: { eq: "heating.png" }) {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  portfolio: file(relativePath: { eq: "portfolio.png" }) {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  icons: file(relativePath: { eq: "icons.png" }) {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  selectAr: file(relativePath: { eq: "selectAr.png" }) {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  portfolioTemplate: file(relativePath: { eq: "portfolioTemplate.png" }) {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  billionDollar: file(relativePath: { eq: "billionDollar.png" }) {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  webDesignPreview: file(relativePath: { eq: "lowFirmWebDesign.png" }) {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  iconsPreview: file(relativePath: { eq: "iconsPreview.png" }) {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  heatingPreview: file(relativePath: { eq: "heatingPreview.png" }) {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  },
},
`;
