import React from 'react';
import * as S from './styles';
import Card from './../card';

const Grid = ({ projects, data, action }) => {
  return (
    <S.Grid>
      {projects.map((project, i) => <S.Grid.Column>
        <Card key={i} id={i} {...project} fluid={data[project.img.src]} action={action}>
          {project.title}
        </Card>
      </S.Grid.Column>)}
    </S.Grid>
  );
};

export default Grid;
