import React, { Component } from 'react';

export const ProjectsContext = React.createContext({});

class ProjectsManager extends Component {
  state = {
    activeProjectIndex: 0,
    isModalActive: false
  }

  setActiveIndex = (index) => {
    this.setState(state => state.activeProjectIndex = index)
  }

 slideTo = (reverse = false) => {
    let index = this.state.activeProjectIndex;
    index += reverse ? 1 : -1;

    if (index < 0) { index = 0; }
    this.setState(state => state.activeProjectIndex = index);
  }

  toggleModal = () => {
    this.setState(state => state.isModalActive = !state.isModalActive);
  }

  render() {
    return (
      <ProjectsContext.Provider value={{
        action: this.setActiveIndex,
        slideTo: this.slideTo,
        state: this.state.activeProjectIndex,
        isModalActive: this.state.isModalActive,
        toggleModal: this.toggleModal
      }}>
        {this.props.children}
      </ProjectsContext.Provider>
    );
  }
}

export default ProjectsManager;
