import React from 'react';
import * as S from './styles';

const Modal = ({ action, children }) => {

  return (
    <S.Modal>
      <S.Modal.Container>
        <S.Modal.Button onClick={action}/>

        <S.Modal.Content>
          {children}
        </S.Modal.Content>
      </S.Modal.Container>
    </S.Modal>
  );
}

export default Modal;
