import styled from 'styled-components';
import mediaMin, { mediaMinMax, breakpoints } from '../../styles/mediaQueries';

const $_margin = '90px';
const $_column_width_sm = 'calc(100% / 2)';
const $_column_width_md = 'calc(100% / 3)';

export const Grid = styled.ul({
  position: 'relative',
  zIndex: 2,
  display: 'flex',
  flexWrap: 'wrap',
  width: 'calc(100% + 2rem)',
  margin: '0 -1rem',
  listStyle: 'none',
  padding: 0,
  paddingTop: '3rem',

  [mediaMin(breakpoints.lg)]: {
    paddingTop: '10rem',
  },
});

Grid.Column = styled.li({
  minWidth: '100%',
  marginTop: '-1px',
  padding: '1rem',

  [mediaMin(breakpoints.md)]: {
    minWidth: `${$_column_width_sm}`,
  },

  [mediaMinMax(breakpoints.sm, breakpoints.md)]: {
    // '&:nth-child(odd)': {
    //   marginTop: `calc(${$_margin}/2)`,
    // },

    // '&:nth-child(2n + 1)': {
    //   marginTop: `calc(-${$_margin}/2)`,
    // }
  },

  [mediaMin(breakpoints.lg)]: {
    minWidth: `${$_column_width_md}`,

    '&:nth-child(3n + 1), &:nth-child(3n + 3)': {
      marginTop: `-${$_margin}`,
    }
  },
});
