import styled from 'styled-components';

export const Card = styled.article(
  ({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.white,
    overflow: 'hidden',
    cursor: 'pointer',
    boxShadow: '0 0 30px 0 rgba(0, 0, 0, .05)',
    border: `1px solid ${theme.primary}`,
    transition: 'all .3s ease-in-out',

    '&:hover': {
      [Card.Image]: {
        transform: 'scale(1.1)',
      },

      [Card.Content]: {
        transform: 'translateY(100%)',
      },

      [Card.Header]: {
        backgroundColor: theme.secondary,
      },

      [Card.Icon]: {
        display: 'block',
      },
    },
  }),
);

Card.Header = styled.header(({ theme }) => (
  {
    position: 'relative',
    overflow: 'hidden',
    paddingTop: '100%',
    backgroundColor: '#f2f2f2',
    opacity: '1',
    transition: 'background .3s ease',
  }
));

Card.Icon = styled.div(({ theme }) => (
  {
    position: 'absolute',
    top: 'calc((100% - 50px)/2)',
    left: 'calc((100% - 50px)/2)',
    width: '50px', 
    height: '50px',
    display: 'none',

    'svg': {
      transform: 'scale(2.5)',
      color: 'white',

      "&:hover": {
        color: theme.primary,
      }
    }
  }
));

Card.Image = styled.div(({ theme }) => (
  {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'all .3s ease-in-out',
    mixBlendMode: 'luminosity',
    opacity: '.2',
  }
));

Card.Content = styled.section(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  color: theme.white,
  background: theme.primary,
  borderTop: `1px solid ${theme.primary}`,
  transition: 'all .3s ease-in-out'
}));

Card.Title = styled.h2(({ theme }) => (
  {
    position: 'relative',
    margin: 0,
    padding: '1rem',
    paddingRight: '5rem',
    fontSize: '.8rem',
    color: 'inherit',
    borderBottom: '1px solid #F0F0F0',
  }
));

Card.Date = styled.span({
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'inline-block',
  height: '100%',
  padding: '1rem',
  borderLeft: '1px solid #f0f0f0',
  color: 'inherit',
  opacity: .6,
  fontWeight: '400',
});

Card.Tags = styled.div({
  maxWidth: 'calc(100% - 1rem)',
  padding: '1rem',
  fontSize: '.7rem',
  overflowX: 'scroll',
  overflowY: 'hidden',

  '&::-webkit-scrollbar': {
    background: 'transparent',
    height: 0,
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'transparent'
  },
});
